import { createStore } from 'vuex'
import Storage from '@/utils/storage'
import http from '@/api/index'
export enum Role {
    Admin = 'admin',
    TeamOwner = 'leader',
    Developer = 'developer',
    ProjectManager = 'manager'
}
interface Imenu {
    url: string
    title: string
    permission: Role[]
    child?: Imenu[]
}

const accessToken = Storage.localGet('accessToken')
const refreshToken = Storage.localGet('refreshToken')
const userData: any = Storage.localGet('userData')

export default createStore({
    state: {
        teamsData: undefined,
        userData: userData || undefined,
        accessToken: accessToken || undefined,
        refreshToken: refreshToken || undefined,
        roleType: (userData && userData.role) || undefined,
        lang: localStorage.lang || 'zh-CN',
        navMap: [
            { url: '/home', title: 'nav.home', permission: [Role.Admin] },
            { url: '/project', title: 'nav.project', permission: [Role.TeamOwner, Role.Developer, Role.ProjectManager] },
            {
                url: '/users',
                redirect: '/users/active',
                title: 'nav.user',
                permission: [Role.Admin],
                child: [
                    { url: '/users/active', title: 'nav.active', permission: [Role.Admin] },
                    { url: '/users/inactive', title: 'nav.inactive', permission: [Role.Admin] }
                ]
            },
            {
                url: '/members',
                redirect: '/members/confirmed',
                title: 'nav.member',
                permission: [Role.TeamOwner],
                child: [
                    { url: '/members/confirmed', title: 'nav.confirmed', permission: [Role.TeamOwner] },
                    { url: '/members/unconfirmed', title: 'nav.unconfirmed', permission: [Role.TeamOwner] }
                ]
            },
            {
                url: '/resource',
                redirect: '/resource/public',
                title: 'nav.resource',
                permission: [Role.Admin],
                child: [
                    { url: '/resource/public', title: 'nav.publicSrc', permission: [Role.Admin] },
                    { url: '/resource/team', title: 'nav.teamSrc', permission: [Role.Admin] }
                ]
            },
            {
                url: '/spaces',
                title: 'nav.spaces',
                permission: [Role.Admin, Role.TeamOwner]
            }
        ],
        userMap: [
            { url: '/personal/userCenter', title: 'nav.userCenter', permission: [Role.Admin, Role.TeamOwner, Role.Developer, Role.ProjectManager] },
            { url: '/personal/teamInfo', title: 'nav.teamInfo', permission: [Role.TeamOwner] },
            { url: '/help', title: 'nav.help', target: '_blank', permission: [Role.TeamOwner, Role.Developer, Role.ProjectManager] },
            { url: '/login', title: 'nav.logout', permission: [Role.Admin, Role.TeamOwner, Role.Developer, Role.ProjectManager] }
        ]
    },
    getters: {
        // 根据路径url和用户角色roleTyp判断是否有页面访问权限
        hasViewPermission: (state) => (url: string) => {
            let flag = false
            function check(arr: Imenu[]) {
                arr.forEach((item) => {
                    if (item.url === url && item.permission.includes(state.roleType)) {
                        flag = true
                    }
                    if (item.child) check(item.child)
                })
            }
            if (state.roleType) {
                const allMap = state.navMap.concat(state.userMap)
                check(allMap)
                return flag
            } else {
                return false
            }
        },
        isRoleType:
            ({ roleType }) =>
            (type: Role) => {
                if (roleType) {
                    return roleType === type ? true : false
                } else {
                    return false
                }
            },
        isEnglish: ({ lang }) => {
            return lang === 'en-US'
        }
    },
    mutations: {
        setAccessToken: (state, token) => {
            state.accessToken = token
            Storage.localSet('accessToken', state.accessToken)
        },
        setRefreshToken: (state, token) => {
            state.refreshToken = token
            Storage.localSet('refreshToken', state.refreshToken)
        },
        setUserData: (state, data) => {
            state.userData = data
            state.roleType = data.role || undefined
            Storage.localSet('userData', state.userData)
        },
        clearUserData: (state) => {
            state.accessToken = undefined
            state.refreshToken = undefined
            state.userData = undefined
            state.teamsData = undefined
            Storage.localRemove('refreshToken')
            Storage.localRemove('accessToken')
            Storage.localRemove('userData')
        },
        setTeamsData: (state, data) => {
            state.teamsData = data
        }
    },
    actions: {
        getTeamsData(context) {
            http.account.getUserTeams().then(({ data }) => {
                context.commit('setTeamsData', data)
            })
        }
    },
    modules: {}
})
