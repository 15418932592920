import * as request from './request'

// 10.1. 项目列表
export const getProjectList: request.RequestFunc = (params) => request.POST(`/api/my/workspace/page/list`, params)

// 10.2. 创建工作空间
export const addProject: request.RequestFunc = (params) => request.POST(`/api/my/workspace/create`, params)

// 10.3. 修改工作空间
export const modifyProject: request.RequestFunc = (params) => request.POST(`/api/my/workspace/update`, params)

// 10.4. 删除工作空间
export const deleteProject: request.RequestFunc = (id) => request.DELETE(`/api/my/workspace/remove/${id}`)

// 10.5. 项目详情
export const getProjectDetail: request.RequestFunc = (id) => request.GET(`/api/projects/${id}`)

// 10.6. 获取项目执行结果详情
export const getTaskStatus: request.RequestFunc = (projectId, taskId) => request.GET(`/api/my/workspace/${projectId}/status/${taskId}`)

// 10.7. 获取项目最后构建信息
export const getBuildStatus: request.RequestFunc = (projectId) => request.GET(`/api/projects/${projectId}/build-status`)

// 10.8. 启动项目
export const getProjStart: request.RequestFunc = (id) => request.POST(`/api/my/workspace/${id}/start`)

// 10.8. 启动项目
export const getProjStop: request.RequestFunc = (id) => request.POST(`/api/my/workspace/${id}/stop`)

// 10.9. 构建项目
export const getProjBuild: request.RequestFunc = (id, params) => request.POST(`/api/projects/${id}/build`, params)

// 10.10. 构建产物列表
export const getBuildingList: request.RequestFunc = (id) => request.GET(`/api/projects/${id}/buildings`)

// 10.11. 构建产物下载
export const downloadBuildings: request.RequestFunc = (id, params) => request.POST(`/api/projects/${id}/buildings/download`, params)

// 10.12. 打包项目
export const packageProj: request.RequestFunc = (id, params) => request.POST(`/api/projects/${id}/zip`, params)

// 10.13. 项目源码下载
export const downloadSources: request.RequestFunc = (id, params) => request.POST(`/api/projects/${id}/sources/download`, params)

// 10.14. 最近运行的项目
export const lastRunningProjects: request.RequestFunc = (id) => request.GET(`/api/my/workspace/last-running-projects/${id}`)

// 11.1. 获取公钥列表
export const getSSHkey: request.RequestFunc = (params) => request.GET(`/api/key-pairs`, params)

// 11.2. 获取所有公钥列表
export const getAllSSHkey: request.RequestFunc = (params) => request.GET(`/api/all-key-pairs`, params)

// 11.3. 获取当前用户公钥
export const getUserSSHkey: request.RequestFunc = () => request.GET(`/api/key-pair`)
