import i18n from '@/assets/lang/i18n'
import '@/assets/iconfont/iconfont.js'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SvgIcon from '@/components/SvgIcon.vue'
import ArcoVueIcon from '@arco-design/web-vue/es/icon'
import '@arco-design/web-vue/dist/arco.less'
import '@/assets/style/common.less'
import '@/assets/style/arco.reset.less'
import initAxios from '@/api/initAxios'
import moment from 'moment'
import api from '@/api/index'
import {
    Space,
    Dropdown,
    Doption,
    Form,
    FormItem,
    Button,
    Input,
    InputPassword,
    Image,
    ConfigProvider,
    Table,
    TableColumn,
    Select,
    Option,
    Modal,
    Switch,
    Radio,
    RadioGroup,
    InputNumber,
    Message,
    Popconfirm,
    Tooltip,
    Checkbox,
    CheckboxGroup,
    Alert,
    TypographyParagraph,
    Typography,
    Spin,
    Pagination,
    Popover,
    Textarea,
    DatePicker
} from '@arco-design/web-vue'

const app = createApp(App).use(store).use(router).use(i18n).use(ArcoVueIcon)

initAxios(app)
app.provide('$http', api)
app.provide('$message', Message)
app.provide('$modal', Modal)
app.provide('$moment', moment)
app.provide('$t', i18n.global.t)

app.component('Select', Select)
app.component('Pagination', Pagination)
app.component('Popover', Popover)
app.component('Alert', Alert)
app.component('Textarea', Textarea)
app.component('Spin', Spin)
app.component('Checkbox', Checkbox)
app.component('CheckboxGroup', CheckboxGroup)
app.component('Typography', Typography)
app.component('TypographyParagraph', TypographyParagraph)
app.component('Radio', Radio)
app.component('Tooltip', Tooltip)
app.component('Popconfirm', Popconfirm)
app.component('RadioGroup', RadioGroup)
app.component('InputNumber', InputNumber)
app.component('Switch', Switch)
app.component('Modal', Modal)
app.component('Option', Option)
app.component('SvgIcon', SvgIcon)
app.component('Space', Space)
app.component('Dropdown', Dropdown)
app.component('Form', Form)
app.component('FormItem', FormItem)
app.component('Button', Button)
app.component('Input', Input)
app.component('Doption', Doption)
app.component('InputPassword', InputPassword)
app.component('Image', Image)
app.component('ConfigProvider', ConfigProvider)
app.component('Table', Table)
app.component('TableColumn', TableColumn)
app.component('DatePicker', DatePicker)
app.mount('#app')
