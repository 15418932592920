<template>
    <span :class="['icon-btn', { disabled: disabled }]" @mouseover="mouseover" @mouseout="mouseout" @mouseup="mouseout">
        <Tooltip :content="tip" v-if="tip">
            <span>
                <svg :class="['svg-icon', className]" aria-hidden="true" :style="iconStyle">
                    <use :xlink:href="iconName" />
                </svg>
                <span v-if="text" class="text ellipsis" :title="text" :style="textStyle">{{ text }}</span>
            </span>
        </Tooltip>
        <span v-else>
            <svg :class="['svg-icon', className]" aria-hidden="true" :style="iconStyle">
                <use :xlink:href="iconName" />
            </svg>
            <span v-if="text" class="text ellipsis" :title="text" :style="textStyle">{{ text }}</span>
        </span>
    </span>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'SvgIcon',
    props: {
        className: String,
        name: String,
        text: String,
        tip: String,
        textColor: String,
        size: {
            type: [Number, String],
            default: 20
        },
        color: String,
        hoverColor: String,
        disabled: Boolean
    },
    data() {
        return {
            iconStyle: `width:${this.size}px;height:${this.size}px;fill:${this.color ? this.color : ''}`,
            textStyle: `color:${this.textColor || ''}`
        }
    },
    computed: {
        iconName() {
            return `#icon${this.name}`
        }
    },
    methods: {
        mouseover() {
            if (this.hoverColor) {
                this.iconStyle = `width:${this.size}px;height:${this.size}px;fill:${this.hoverColor}`
                this.textStyle = `color:${this.hoverColor}`
            }
        },
        mouseout() {
            this.iconStyle = this.color ? `width:${this.size}px;height:${this.size}px;fill:${this.color}` : `width:${this.size}px;height:${this.size}px;`
            this.textStyle = this.textColor ? `color:${this.textColor}` : ''
        }
    }
})
</script>

<style scoped>
.icon-btn {
    cursor: pointer;
    /* line-height: 20px;
    height: 23px; */
    display: inline-block;
}
.disabled .svg-icon {
    fill: #666666 !important;
}

.svg-icon {
    fill: #666666;
    vertical-align: middle;
}
.text {
    display: inline-block;
    padding-left: 6px;
    font-size: 18px;
    vertical-align: middle;
    max-width: 160px;
}
</style>
