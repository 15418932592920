import { createI18n } from 'vue-i18n'
import messages from './index'
const i18n = createI18n({
    locale: localStorage.lang || 'zh-CN', // 设置地区
    // silentTranslationWarn: true,
    fallbackLocale: 'zh-CN',
    // globalInjection: true,
    // legacy: false,
    messages
})
export default i18n
