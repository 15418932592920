import * as request from './request'

// 1.1 获取验证码
export const getCaptcha: request.RequestFunc = () => request.GET('/api/captcha')

// 2.1 用户登录
export const login: request.RequestFunc = (params) => request.POST('/api/sessions', params)

// 2.2 刷新token
export const refreshToken: request.RequestFunc = (params) => request.PUT('/api/session', params)

// 2.3 用户登出
export const logout: request.RequestFunc = () => request.DELETE('/api/session')

// 3.1 发送找回密码邮件
export const postFindPasswordEmail: request.RequestFunc = (params) => request.POST('/api/password-emails', params)

// 3.2 获取重置密码用户信息
export const getResetPasswordUserInfo: request.RequestFunc = (code) => request.GET(`/api/password-emails/${code}`)

// 3.3 重置用户密码
export const resetPassword: request.RequestFunc = (code: string, params) => request.PUT(`/api/password-emails/${code}`, params)

// 4.1 团队拥有者列表
export const getUsers: request.RequestFunc = (params) => request.POST('/api/admin/user/list', params)

// 4.2 添加团队用户
export const addUser: request.RequestFunc = (params) => request.POST('/api/admin/team/create', params)

// 4.3 删除团队拥有者
export const deleteUser: request.RequestFunc = (id) => request.DELETE(`/api/admin/user/delete/${id}`)

// 4.4. 修改团队拥有者
export const modifyUser: request.RequestFunc = (params) => request.PUT(`/api/admin/user/update`, params)

// 5.1 获取激活用户信息
export const getActivationUserInfo: request.RequestFunc = (code) => request.GET(`/api/activation-emails/${code}`)

// 5.2 重发激活邮件
export const resendActivationEmail: request.RequestFunc = (params) => request.POST('/api/activation-emails', params)

// 5.3 激活用户
export const activateUser: request.RequestFunc = (code: string, params) => request.PUT(`/api/activation-emails/${code}`, params)

// 6.1 子成员列表
export const getMembers: request.RequestFunc = (params) => request.POST('/api/members/list', params)

// 6.2 添加团队子成员
export const addMember: request.RequestFunc = (params) => request.POST('/api/members/add', params)

// 6.3 删除团队子成员
export const deleteMember: request.RequestFunc = (teamId, userId) => request.DELETE(`/api/members/${teamId}/${userId}`)

// 6.4. 修改团队成员
export const modifyMember: request.RequestFunc = (id, params) => request.PUT(`/api/members/${id}`, params)

// 6.5. 重置团队成员公私钥对
export const resetMemberSSH: request.RequestFunc = (id) => request.PUT(`/api/members/${id}/key-pair`)

// 6.6. 团队成员详情
export const getMember: request.RequestFunc = (id) => request.GET(`/api/members/${id}`)

// 7.1 修改用户密码
export const modifyPassword: request.RequestFunc = (params) => request.PUT(`/api/user/password`, params)

// 7.2 修改用户信息 废弃 使用修改子成员接口进行替代
export const setUserInfoById: request.RequestFunc = (id, params) => request.PUT(`/api/users/${id}`, params)

// 7.3 修改当前用户信息
export const setUserInfo: request.RequestFunc = (params) => request.PUT(`/api/user`, params)

// 7.4 获取指定用户详情 废弃 使用获取团队成员详情接口进行替代
export const getUserInfoById: request.RequestFunc = (id) => request.GET(`/api/users/${id}`)

// 7.5 获取当前用户详情
export const getUserInfo: request.RequestFunc = () => request.GET(`/api/user`)

// 7.6 删除指定用户 废弃 使用删除成员和删除团队负责人接口删除用户
export const deleteUserById: request.RequestFunc = (id) => request.DELETE(`/api/users/${id}`)

// 8.1 团队列表
export const getTeams: request.RequestFunc = (params) => request.GET('/api/admin/team/list', params)

// 为添加资源时所属团队下拉列表
export const getTeamOption: request.RequestFunc = () => request.GET('/api/admin/team/select')

// 8.2 团队详情
export const getTeamInfoById: request.RequestFunc = (id) => request.GET(`/api/my/workspace/detail/${id}`)

// 8.3 当前团队详情
export const getTeamInfo: request.RequestFunc = () => request.GET('/api/team')

// 8.3 删除团队
export const deleteTeam: request.RequestFunc = (id) => request.DELETE(`/api/admin/team/remove/${id}`)

// 8.4 编辑团队信息
export const editTeamInfoById: request.RequestFunc = (params) => request.PUT(`/api/admin/team/update`, params)

// 8.5 编辑当前团队信息
export const editTeamInfo: request.RequestFunc = (params) => request.POST('/api/team/update', params)

// 8.5 获取当前用户下所有的团队列表
export const getUserTeams: request.RequestFunc = () => request.GET('/api/team/switch/list')

// 8.5 切换团队
export const switchTeam: request.RequestFunc = (params) => request.POST('/api/team/switch', params)

// 8.5 转移团队
export const moveTeam: request.RequestFunc = (params) => request.POST('/api/team/move', params)

// 9.1 发送更改邮箱邮件
export const changeEmail: request.RequestFunc = (params) => request.POST('/api/alteration-emails', params)

// 9.2 获取更改邮箱用户信息
export const getChangeEmailInfo: request.RequestFunc = (code) => request.GET(`/api/alteration-emails/${code}`)

// 邀请已激活用户加入团队
export const getInviteUserInfo: request.RequestFunc = (code) => request.GET(`/api/invite/user/${code}`)

// 9.3 更新更改邮箱信息
export const changeEmailInfo: request.RequestFunc = (code: string, params) => request.PUT(`/api/alteration-emails/${code}`, params)

// 10.1 帮助文档
export const getDocUrl: request.RequestFunc = (id) => request.GET(`/api/documents/${id}`)

// 10.1 隐私合规用户协议
export const getPrivacyDocUrl: request.RequestFunc = (id) => request.GET(`/api/documents/privacy/${id}`)

// 分页获取资源列表
export const getResource: request.RequestFunc = (params) => request.POST('/api/admin/team/resource/list', params)

// 添加资源
export const addResource: request.RequestFunc = (params) => request.POST('/api/admin/team/resource/add', params)

// 配置资源
export const updateResource: request.RequestFunc = (params) => request.PUT('/api/admin/team/resource/update', params)

// 配置公共资源
export const updateTrialResource: request.RequestFunc = (params) => request.PUT('/api/admin/team/resource/trial/update', params)

// 删除资源
export const deleteResource: request.RequestFunc = (id) => request.DELETE(`/api/admin/team/resource/remove/${id}`)

// 空间列表(管理员)
export const getWorkspaceList: request.RequestFunc = (params) => request.POST(`/api/admin/workspace/page/list`, params)

// 删除工作空间(管理员)
export const deleteSpace: request.RequestFunc = (id) => request.DELETE(`/api/admin/workspace/remove/${id}`)

// 空间列表(leader)
export const getLeaderWorkspaceList: request.RequestFunc = (params) => request.POST(`/api/leader/workspace/page/list`, params)

// 删除工作空间(leader)
export const deleteLeaderSpace: request.RequestFunc = (id) => request.DELETE(`/api/leader/workspace/remove/${id}`)

// 用户申请注销账户
export const destoryAccount: request.RequestFunc = () => request.GET(`/api/user/delete/apply`)

// 团队详情
export const getTeamDetail: request.RequestFunc = (id) => request.GET(`/api/team/detail/${id}`)

// 同意隐私协议政策内容
export const agree: request.RequestFunc = (type) => request.GET(`/api/user/privacy/accept/${type}`)
