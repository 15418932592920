import axios from './request'
import { AxiosRequestConfig } from 'axios'
import { App } from 'vue'
import { Message } from '@arco-design/web-vue'
import i18n from '@/assets/lang/i18n'
import api from '@/api/index'
import Storage from '@/utils/storage'
const noNeedMsg = {
    post: [/\/api\/password-emails/, /\/api\/projects\/\d+\/start/],
    get: [/\/api\/password-emails\//, /\/api\/activation-emails\//, /\/api\/alteration-emails\//, /\/api\/invite\/user\//],
    put: [/\/api\/password-emails\//, /\/api\/activation-emails\//, /\/api\/alteration-emails\//],
    delete: []
}

export default function initAxios(app: App): void {
    const store = app.config.globalProperties.$store
    const router = app.config.globalProperties.$router
    Object.assign(axios.defaults, {
        baseURL: process.env.BASE_URL,
        timeout: 60000 * 5,
        withCredentials: true
    })
    // 添加请求拦截器
    axios.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            // 在发送请求之前做些什么
            const accessToken = Storage.localGet('accessToken')
            if (store.state.accessToken && accessToken !== store.state.accessToken) {
                router.go(0)
            }
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`
            }
            config.headers['Accept-Language'] = localStorage.lang || 'zh-CN'
            return encodeGetParams(config)
        },
        (error) => {
            // 对请求错误做些什么
            return Promise.reject(error)
        }
    )

    // 添加响应拦截器
    axios.interceptors.response.use(
        (response) => {
            // 对响应数据做点什么
            return response
        },
        (error) => {
            if (error.message === 'Network Error') {
                Message.clear('top')
                Message.error(i18n.global.t('netError'))
                return Promise.reject(error)
            }
            const { status } = error.response
            const config = error.config
            if (status === 401 && !config.url.includes('/api/session')) {
                if (error.response.data.code === 1000004) {
                    // 用户被禁用或者用户没有团队
                    store.commit('clearUserData')
                    router.push('/login')
                    Message.clear('top')
                    Message.error(error.response.data.message)
                } else {
                    refreshToken(500)
                }
                return Promise.reject(error)
            } else if (status === 403) {
                router.replace('/noPermission')
            } else if (status === 404) {
                router.push('/notFound')
            } else if (status === 500) {
                router.replace('/serverError')
            } else {
                let needMsg = true
                for (const item of noNeedMsg[config.method]) {
                    if (item.test(config.url)) {
                        needMsg = false
                        break
                    }
                }
                if (needMsg) {
                    Message.clear('top')
                    Message.error(error.response.data.message)
                }
                return Promise.reject(error)
            }
        }
    )

    let timeid = null
    function refreshToken(wait = 0) {
        if (timeid) {
            clearTimeout(timeid)
        }
        timeid = setTimeout(() => {
            const token = Storage.localGet('refreshToken')
            return api.account
                .refreshToken({ refreshToken: token })
                .then(({ data }) => {
                    store.commit('setAccessToken', data.accessToken)
                    store.commit('setRefreshToken', data.refreshToken)
                    router.go(0)
                })
                .catch(() => {
                    store.commit('clearUserData')
                    router.push('/login')
                    Message.clear('top')
                    Message.error(i18n.global.t('loginTimeout'))
                })
        }, wait)
    }
}

function encodeGetParams(config: AxiosRequestConfig): AxiosRequestConfig {
    let url = config.url
    if (config.method === 'get' && config.params) {
        url += '?'
        const keys = Object.keys(config.params)
        for (const key of keys) {
            url += `${key}=${encodeURI(config.params[key])}&`
        }
        url = url.substring(0, url.length - 1)
        config.params = {}
    }
    config.url = url
    return config
}
