import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store, { Role } from '../store'
type redirectMap = {
    [index in Role]: string
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'mainBox',
        redirect: (): string => {
            // 1、根据用户角色重定向到相应页面
            // 2、进入路由守卫
            const roleType = store.state.roleType
            if (roleType) {
                const map: redirectMap = {
                    admin: '/home',
                    leader: '/project',
                    developer: '/project',
                    manager: '/project'
                }
                return map[roleType] ? map[roleType] : '/notFound'
            } else {
                return '/home'
            }
        },
        component: () => import(/* webpackChunkName: "MainBox" */ '../views/layout/MainBox.vue'),
        children: [
            {
                path: '/home',
                name: 'home',
                meta: {
                    needPermission: true
                },
                component: () => import(/* webpackChunkName: "Home" */ '../views/home/Home.vue')
            },
            {
                path: '/users/:type',
                name: 'user',
                meta: {
                    needPermission: true
                },
                component: () => import(/* webpackChunkName: "User" */ '../views/userTable/Index.vue')
            },
            {
                path: '/members/:type',
                name: 'member',
                meta: {
                    needPermission: true
                },
                component: () => import(/* webpackChunkName: "User" */ '../views/userTable/Index.vue')
            },
            {
                path: '/resource/:type',
                name: 'resource',
                meta: {
                    needPermission: true
                },
                component: () => import(/* webpackChunkName: "Resource" */ '../views/resource/Index.vue')
            },
            {
                path: '/project',
                name: 'project',
                meta: {
                    needPermission: true
                },
                component: () => import(/* webpackChunkName: "Project" */ '../views/project/Project.vue')
            },
            {
                path: '/spaces',
                name: 'spaces',
                meta: {
                    needPermission: true
                },
                component: () => import(/* webpackChunkName: "Spaces" */ '../views/spaces/Index.vue')
            },
            {
                path: '/personal/userCenter',
                name: 'userCenter',
                meta: {
                    needPermission: true
                },
                component: () => import(/* webpackChunkName: "Active" */ '../views/userCenter/UserCenter.vue')
            },
            {
                path: '/personal/teamInfo',
                name: 'teamInfo',
                meta: {
                    needPermission: true
                },
                component: () => import(/* webpackChunkName: "Inactive" */ '../views/userCenter/TeamInfo.vue')
            },
            {
                path: '/noPermission',
                name: 'noPermission',
                component: () => import(/* webpackChunkName: "NoPermission" */ '../views/error/NoPermission.vue')
            }
        ]
    },
    {
        path: '/help',
        name: 'help',
        component: () => import(/* webpackChunkName: "Help" */ '../views/layout/Help.vue')
    },
    {
        path: '/terms/:type',
        name: 'terms',
        component: () => import(/* webpackChunkName: "Terms" */ '../views/publicView/Terms.vue')
    },
    {
        path: '/startup/:projId',
        name: 'startup',
        component: () => import(/* webpackChunkName: "Startup" */ '../views/project/Startup.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/publicView/Login.vue')
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: () => import(/* webpackChunkName: "ForgetPSW" */ '../views/publicView/ForgetPSW.vue')
    },
    {
        path: '/:pathMatch(find/password|active/account|alter/email|invite/user)',
        name: 'setPassword',
        component: () => import(/* webpackChunkName: "SetPSW" */ '../views/publicView/SetPSW.vue')
    },
    {
        path: '/notFound',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/error/NotFound.vue')
    },
    {
        name: 'serverError',
        path: '/serverError',
        component: () => import(/* webpackChunkName: "ServerError" */ '../views/error/ServerError.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/notFound'
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (store.state.accessToken && store.state.userData) {
        // 判断是否有登录token
        if (to.path === '/login') {
            next('/')
        } else if (to.meta.needPermission && !store.getters.hasViewPermission(to.path)) {
            // 访问需要权限的页面如果没有相应权限重定向到notfound
            next('/notFound')
        }
        next()
    } else {
        if (!to.meta.needPermission) {
            next()
        } else {
            next('/login')
        }
    }
})
export default router
