const Storage = {
    // ==================sessionStorage设置缓存================
    // 设置缓存
    sessionSet: (name: string, data: unknown): void => {
        window.sessionStorage.removeItem(name)
        window.sessionStorage.setItem(name, JSON.stringify(data))
    },
    // 获取缓存
    sessionGet: (name: string): unknown => {
        const data = window.sessionStorage.getItem(name)
        if (data && data !== 'undefined') {
            return JSON.parse(data)
        } else {
            return undefined
        }
    },
    // 清除缓存
    sessionRemove: (name: string): void => {
        window.sessionStorage.removeItem(name)
    },
    // ==================localStorage设置缓存==================
    // 设置缓存
    localSet: (name: string, data: unknown): void => {
        window.localStorage.removeItem(name)
        window.localStorage.setItem(name, JSON.stringify(data))
    },
    // 获取缓存
    localGet: (name: string): unknown => {
        const data = window.localStorage.getItem(name)
        if (data && data !== 'undefined') {
            return JSON.parse(data)
        } else {
            return undefined
        }
    },
    // 清除缓存
    localRemove: (name: string): void => {
        window.localStorage.removeItem(name)
    }
}

export default Storage
