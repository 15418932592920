const zh = {
    ok: '确定',
    close: '关闭',
    cancel: '取消',
    submit: '提交',
    format: '格式化',
    complete: '完成',
    copy: '复制',
    destory: '注销',
    copied: '复制成功',
    save: '保存',
    agree: '同意',
    disagree: '不同意',
    config: '配置',
    prompt: '提示',
    detail: '详情',
    delete: '删除',
    enable: '启用',
    disable: '禁用',
    transfer: '转让',
    empty: '空',
    git: 'Git仓库',
    add: '添加',
    create: '新建',
    modify: '修改',
    modifyInfo: '修改{info}',
    change: '变更',
    operation: '操作',
    delSucc: '删除成功',
    removeSucc: '移除成功',
    update: '修改',
    submitSucc: '提交成功',
    saveSucc: '保存成功',
    transferSucc: '转让成功',
    oparationSucc: '操作成功',
    addSucc: '添加成功',
    inviteSucc: '邀请成功',
    modifySucc: '修改成功',
    updateSucc: '更新成功',
    logout: '登出',
    noPermission: '没有权限',
    netError: '网络异常',
    loginTimeout: '账号登录已过期，请重新登录',
    remoteLoginErr: '帐号在异地登录，自动登出',
    noMore: '没有更多了',
    noData: '暂无数据',
    projectNum: '空间数量',
    memberNum: '成员数量',
    total: '共 {count} 条',
    teamVersion: '团队版',
    trialVersion: '试用版',
    deleteSpace: '删除空间',
    goToView: '前往查看',
    invite: '邀请',
    invitationTime: '邀请时间',
    expired: '已过期',
    trialExpiredTip: '试用空间已到期',
    stop: '停止',
    start: '启动',
    systemEnv: '系统环境',
    publicSrc: '公共资源',
    teamSrc: '团队资源',
    resource: {
        addSrc: '添加资源',
        confSrc: '资源配置',
        confPublicSrc: '公共资源配置',
        teamOwner: '团队拥有者',
        ip: '服务器IP',
        specification: '规格',
        storage: '存储',
        spaceNum: '空间数',
        runSpace: '运行空间',
        runningSpace: '可同时运行空间',
        runningLimit: '运行限制',
        createTime: '添加时间',
        expireTime: '到期时间',
        spaceName: '空间名',
        creator: '创建者'
    },
    nav: {
        home: '首页',
        user: '用户管理',
        member: '成员管理',
        project: '我的空间',
        resource: '资源管理',
        spaces: '空间管理',
        inactive: '待激活',
        active: '已激活',
        confirmed: '已确认',
        unconfirmed: '待确认',
        publicSrc: '公共资源',
        teamSrc: '团队资源',
        subUser: '子成员',
        userCenter: '个人中心',
        teamInfo: '团队信息',
        help: '帮助文档',
        logout: '登出'
    },
    project: {
        setUp: '设置',
        success: '成功',
        fail: '失败',
        stopping: '停止中',
        readwrite: '读写',
        download: '下载',
        mainRepository: '主库',
        workingCopy: '工作副本',
        log: '记录',
        build: '构建',
        createAt: '创建时间：',
        recentlyRun: '最近运行',
        recentlyOpened: '最近打开：',
        projectList: '项目列表',
        programGuide: '编程指南',
        leader: '团队管理员',
        developer: '开发人员',
        manager: '项目管理员',
        addProj: '+ 工作空间',
        addWorkspace: '新建工作空间',
        modifyProj: '空间设置',
        projMembers: '项目成员',
        quickStart: '快速开始',
        projectName: '空间名称',
        projectTitle: '标题',
        type: '类型',
        desc: '描述',
        codeRcord: '代码提交记录',
        submitter: '提交人',
        submitTime: '提交时间',
        version: '版本',
        codeSrc: '代码来源',
        startBuild: '开始构建',
        cancleBuild: '取消构建',
        buildFailed: '构建失败',
        buildSucceeded: '构建成功',
        buildTime: '构建时间',
        buildLog: '构建日志',
        copyLog: '复制构建日志',
        package: '构件',
        stillOpenIDE: '仍然打开IDE',
        openWorkspace: '正在打开工作区',
        commonApp: '普通应用',
        staticLib: '静态库',
        dynamicLib: '动态库',
        porlinSummary: '由百富开发的一款支持多应用多进程的安全高效的POS操作系统平台',
        runthosSummary: '由百富开发的一款支持物联网的实时、安全、低功耗的操作系统平台',
        tryTip: '试用版将于{time}到期，到期后仍可访问，但无法继续运行空间，建议尽快购买团队版。'
    },
    user: {
        user: '用户',
        copyssh: '复制公钥',
        sshkey: 'SSH公钥',
        resetKey: '重置公钥',
        publicKeyList: '成员公钥',
        name: '用户名',
        email: '邮箱',
        phone: '联系方式',
        project: '项目',
        addTime: '添加时间',
        createTime: '生成时间',
        role: '角色',
        addMember: '邀请成员',
        editMember: '修改成员',
        addTeam: '添加团队',
        team: '所属团队',
        sendEmail: '重发邮件',
        userInfo: '用户信息',
        teamInfo: '团队信息',
        platform: '支持项目类型',
        warehouse: '仓库',
        basicInfo: '基本信息',
        accountSecurity: '账号安全',
        loginPassword: '登录密码',
        destoryAccount: '账号注销',
        bindEmail: '绑定邮箱',
        teamName: '团队名',
        accountSource: '来源',
        thisSystem: '本系统',
        ownership: '团队所有权',
        transferTeam: '转让团队'
    },
    login: {
        name: '用户名',
        email: '邮箱',
        newEmail: '新邮箱',
        phone: '联系方式',
        username: '用户名/邮箱',
        infoName: '用户名/邮箱/工号',
        password: '密码',
        newPassword: '新密码',
        oldPassword: '旧密码',
        confirmPsw: '确认密码',
        code: '验证码',
        login: '登录',
        regBtn: '立即注册',
        forgetPwd: '忘记密码',
        activeAccount: '账号激活',
        resetPassword: '重置密码',
        modifyEmail: '修改邮箱',
        retrievePassword: '找回密码',
        backToLogin: '已有账号？ 返回登录',
        infoLogin: 'INFO账号',
        sysLogin: '系统账号'
    },
    tip: {
        confirmDestoryAccountTip: '是否确认注销账号？',
        destoryAccountEmailTip: '已发送注销申请给账号管理员，请耐心等待管理员与您联系',
        noResourceTip: '无可用资源，请联系管理员',
        noProjTip: '暂时还没有工作空间',
        noTeamTip: '请先联系管理员邀请进入团队',
        activeSysAcount: '请完善账号信息完成账号激活',
        activeInfoAcount: '请使用INFO账号登录授权',
        startingContainerTip: '正在启动构建容器...',
        resetSSHKeyTip: '是否重置 {username} 访问公钥？',
        deleteProjTip: '删除后该项目所有工作副本均不可恢复，确认删除项目 {proj}？',
        ownershipTip: '若团队权限及数据资产需要移交给其他人员，可通过帐号认证进行转让',
        emptyTip: '{key}不能为空',
        errorAddressRegTip: '仓库地址须以 git@ 或 https:// 或 http:// 开头，以.git结尾',
        warehouseAddressRegTip: '请输入100个字符以内的仓库地址',
        projectNameRegTip: '请输入1~64个字符以内的项目名称',
        projectNameTip1: '不可使用小数点开头',
        projectNameTip2: '不可使用中划线开头',
        projectNameTip: '仅允许输入数字、字母、小数点、中划线-、下划线_',
        projectTitleRegTip: '请输入1~64个字符以内的空间名',
        teamNameTip: '请输入4~50个数字或字母',
        changePasswordTip: '为保证账户使用安全，建议您定期更换密码',
        changeEmailTip: '更换绑定邮箱只影响登录使用的邮箱，不会影响您当前帐号的相关权限',
        destoryAccountTip: '注销账号将删除包含工作空间、代码在内的所有个人数据及上网记录',
        copySSHkeyTip: '请将该SSH公钥添加至个人公钥列表，方便您推拉代码',
        nameRegTip: '请输入以字母开头的6~20个字符，只包含字母或数字',
        emailRegTip: '请输入1~50个字符的邮箱',
        emailRegTip1: '邮箱格式不正确',
        emailRegTip2: '邮箱结尾需为paxsz.com',
        numberTip: '请输入{text}的整数',
        ipTip: '服务器IP格式不正确',
        userNameTip: '请输入6-20位数字或字母',
        pswTip: '请输入6~20个字符的密码',
        lengthTip: '请输入{min}~{max}个字符',
        pswRegTip: '密码需包含大小写字母和数字',
        confirmPswTip: '请再次输入密码',
        pswDifferentTip: '两次密码不一致',
        spaceNameDifferentTip: '空间名不一致',
        pswSameTip: '新密码与旧密码不能相同',
        phoneTip: '请输入20个字符以内的联系方式',
        activatedSuccessTip: '您的账号已成功激活，请点击登录',
        resetPswTip: '您的密码已重置成功，请点击登录',
        bindEmailTip: '您的邮箱已绑定成功，请点击登录',
        disableUserTip: '禁用后该用户将不可使用',
        deleteUserTip: '是否删除用户 {name}？',
        removeUserTip: '是否移除用户 {name}？',
        deleteTeamTip: '确认删除团队 {name}？',
        deleteResourceTip: '是否删除 {name} 团队的{resource}？',
        sendEmailTip: '是否重新发送激活邮件？',
        sendEmailSuccessTip: '激活邮件已发送，请提醒用户完成帐号激活',
        changeEmailSuccessTip: '我们将发送一封邮件至您的{email}，请查收；并于48小时内根据邮件引导完成操作',
        termTip: '请先阅读并同意用户协议、隐私政策',
        modifyPhoneTip: '联系方式可随时修改和删除。填写即表示您同意我们收集这些数据。请参阅我们的隐私政策以了解更多。',
        deleteSpaceTip: '空间删除后无法恢复，如需删除，请在下方输入需要删除的空间名称：{name} 并确认',
        inviteSuccTip: '已成功加入‘{name}’团队',
        expiredTip: '已过期，续费可恢复',
        trialExpriedTip: '试用空间将在{time}到期',
        teamExpriedTip: '团队空间将在{time}到期',
        trialRenewalTip: '试用版已过期，请联系管理员购买团队版',
        trialRenewalTip1: '该试用空间已过期',
        trialRunningTip: '可同时运行的试用空间已达到最大值',
        teamRunningTip: '可同时运行的团队空间已达到最大值',
        teamRenewalTip: '该团队资源已过期',
        trialNumRenewalTip: '当前试用空间数到达限制,请删除现有空间后再添加或联系管理员升级',
        teamNumRenewalTip: '当前团队空间数到达限制,请删除现有空间后再添加或联系管理员升级'
    },
    holder: {
        regNameHolder: '只包含字母或数字',
        warehouseAddressTip: '请输入Git仓库地址，HTTPS只能克隆公开仓库',
        regPasswordHolder: '包含大小写字母和数字',
        enterHolder: '请输入',
        selectHolder: '请选择',
        inputHolder: '请输入{key}',
        teamNameHolder: '请输入团队名称',
        warehouseAddressHolder: '请输入仓库',
        emailHolder: '请输入邮箱',
        phoneHolder: '请输入联系方式',
        nameHolder: '请输入用户名',
        passwordHolder: '请输入密码',
        infoPasswordHolder: '请输入info系统密码',
        checkPasswordHolder: '再次确认密码'
    }
}
export default zh
