const en = {
    ok: 'OK',
    close: 'Close',
    cancel: 'Cancel',
    submit: 'Submit',
    format: 'Format',
    complete: 'Complete',
    save: 'Save',
    agree: 'Agree',
    disagree: 'Disagree',
    copy: 'copy',
    destory: 'Delete',
    copied: 'copied',
    config: 'Config',
    prompt: 'Prompt',
    detail: 'Detail',
    delete: 'Delete',
    transfer: 'Transfer',
    empty: 'Empty',
    git: 'Git',
    enable: 'Enable',
    disable: 'Disable',
    create: 'Create',
    add: 'Add',
    modify: 'Modify',
    modifyInfo: 'Modify {info}',
    change: 'Change',
    operation: 'Operation',
    delSucc: 'Delete Successfully',
    removeSucc: 'Remove Successfully',
    update: 'Update',
    submitSucc: 'Submit Successfully',
    saveSucc: 'Save Successfully',
    oparationSucc: 'Operate successfully',
    addSucc: 'Add successfully',
    inviteSucc: 'Invite successful',
    transferSucc: 'Transfer successfully',
    modifySucc: 'Modify Successfully',
    updateSucc: 'Update Successfully',
    logout: 'Logout',
    noPermission: 'Permission denied',
    netError: 'Network Error',
    loginTimeout: 'Login timeout, please login again',
    remoteLoginErr: 'Force logoff for remote login',
    noMore: 'No more',
    noData: 'No Data',
    projectNum: 'Spaces',
    memberNum: 'Members',
    total: 'Total: {count}',
    teamVersion: 'Team Version',
    trialVersion: 'Trial Version',
    deleteSpace: 'Delete Workspace',
    goToView: 'Go To View',
    invite: 'Invite',
    invitationTime: 'Invitation Time',
    expired: 'Expired',
    trialExpiredTip: 'Trial space has expired',
    stop: 'Stop',
    start: 'Start',
    systemEnv: 'System Environment',
    publicSrc: 'public resource',
    teamSrc: 'team resource',
    resource: {
        addSrc: 'Add Resource',
        confSrc: 'Configure Resources',
        confPublicSrc: 'Configure Public Resources',
        teamOwner: 'Team Owner',
        ip: 'Server IP',
        specification: 'Specification',
        storage: 'Storage',
        spaceNum: 'Space',
        runSpace: 'Running Space',
        runningSpace: 'Running space at the same time',
        runningLimit: 'Running Limit',
        createTime: 'Created at',
        expireTime: 'Expired by',
        spaceName: 'Space Name',
        creator: 'Created by'
    },
    nav: {
        home: 'Home',
        user: 'Users',
        member: 'Members',
        project: 'My Space',
        resource: 'Resource',
        spaces: 'Spaces',
        active: 'Activated',
        inactive: 'Inactivated',
        confirmed: 'Confirmed',
        unconfirmed: 'To be confirmed',
        publicSrc: 'Public Resource',
        teamSrc: 'Team Resource',
        subUser: 'Sub User',
        userCenter: 'Personal',
        teamInfo: 'Team Info',
        help: 'Help Doc',
        logout: 'Logout'
    },
    project: {
        setUp: 'Settings',
        success: 'Success',
        fail: 'Failure',
        stopping: 'Stopping',
        readwrite: 'Read&Write',
        download: 'Download',
        mainRepository: 'Main Repository',
        workingCopy: 'Working Copy',
        log: 'Log',
        createAt: 'Created At:  ',
        recentlyRun: 'Recently Run',
        recentlyOpened: 'Recently Opened: ',
        projectList: 'Project List',
        programGuide: 'Programming Guide',
        build: 'Build',
        leader: 'Leader',
        developer: 'Developer',
        manager: 'Manager',
        addProj: '+ Workspace',
        addWorkspace: 'Add Workspace',
        modifyProj: 'Space Details',
        projMembers: 'Project Members',
        quickStart: 'Quick Start',
        projectName: 'Space Name',
        projectTitle: 'Title',
        type: 'Type',
        desc: 'Description',
        codeRcord: 'Code Submission Record',
        submitter: 'Submitted by',
        submitTime: 'Submitted at',
        version: 'Version',
        codeSrc: 'Code Source',
        startBuild: 'Start Building',
        cancleBuild: 'Cancle Building',
        buildFailed: 'Build Failed',
        buildSucceeded: 'Build Succeeded',
        buildTime: 'Build Time',
        buildLog: 'Build Log',
        copyLog: 'Copy Build Log',
        package: 'Package',
        stillOpenIDE: 'Still open the IDE',
        openWorkspace: 'Opening Workspace',
        commonApp: 'Common Application',
        staticLib: 'Static Library',
        dynamicLib: 'Dynamic Library',
        porlinSummary: 'A safe and efficient POS operating system platform that supports multi-application and multi-process developed by PAX',
        runthosSummary: 'A real-time, secure, low-power operating system platform that supports internet of things developed by PAX',
        tryTip: 'The trial version will expire at {time}. After the expiration, you can still access it, but you cannot continue to run the space. It is recommended to purchase the team version as soon as possible.'
    },
    user: {
        user: 'User',
        copyssh: 'Copy Public Key',
        sshkey: 'SSH Public Key',
        resetKey: 'Reset Public Key',
        publicKeyList: 'Key Of Members',
        name: 'Name',
        email: 'Email',
        phone: 'Phone Number',
        project: 'Project',
        addTime: 'Added At',
        createTime: 'Created At',
        role: 'Role',
        addMember: 'Invite Member',
        editMember: 'Modify Member',
        addTeam: 'Add team',
        team: 'Team',
        sendEmail: 'Resend Email',
        userInfo: 'User Info',
        teamInfo: 'Team Info',
        platform: 'Supported Project Type',
        warehouse: 'Repository',
        basicInfo: 'Basic Information',
        accountSecurity: 'Account Security',
        loginPassword: 'Login Password',
        destoryAccount: 'Delete Account',
        bindEmail: 'Binding Email',
        teamName: 'Team Name',
        accountSource: 'Source',
        thisSystem: 'System',
        ownership: 'Ownership of the team',
        transferTeam: 'Transfer Team'
    },
    login: {
        name: 'Name',
        email: 'Email',
        newEmail: 'New Email',
        phone: 'Phone Number',
        username: 'Name/Email',
        infoName: 'Name/Email/Job number',
        password: 'Password',
        newPassword: 'New Password',
        oldPassword: 'Old Password',
        confirmPsw: 'Confirm Password',
        code: 'Verification Code',
        login: 'Login',
        regBtn: 'Sign Up',
        forgetPwd: 'Forgot Password',
        activeAccount: 'Account Activation',
        resetPassword: 'Reset Password',
        modifyEmail: 'Modify Email',
        retrievePassword: 'Retrieve Password',
        backToLogin: 'Back to login',
        infoLogin: 'INFO Account',
        sysLogin: 'System Account'
    },
    tip: {
        confirmDestoryAccountTip: 'Are you sure to cancel the account?',
        destoryAccountEmailTip:
            'A cancellation request has been sent to the account administrator, please wait patiently for the administrator to contact you.',
        noResourceTip: 'No resource available, please contact administrator',
        noProjTip: 'There is no workspace',
        noTeamTip: 'Please contact the administrator first to invite into the team',
        activeSysAcount: 'Please complete the following information',
        activeInfoAcount: 'Please use INFO account to authorize',
        startingContainerTip: 'Starting build container...',
        resetSSHKeyTip: 'Whether to reset the {username} access public key?',
        deleteProjTip: 'After deletion, the copies of the project cannot be recovered. Are you sure to delete the project {proj}?',
        ownershipTip: 'The team permissions and data assets can be transferred to other personnel through account authentication.',
        emptyTip: '{key} is required',
        errorAddressRegTip: 'The repository URL must start with git@ or https:// or http:// and end with .git',
        warehouseAddressRegTip: 'Please enter a warehouse address within 100 characters',
        projectNameRegTip: 'Please enter a repository URL within 100 characters',
        projectNameTip1: 'Cannot start with a decimal point(.).',
        projectNameTip2: 'Cannot start with a hyphen-minus(-).',
        projectNameTip: 'Numbers, letters, (.), (-) and (_) only',
        projectTitleRegTip: 'Please enter a space name within 64 characters',
        teamNameTip: 'Please enter 4~50 numbers or letters.',
        changePasswordTip: 'It is recommended that you change your password regularly to ensure account security.',
        changeEmailTip: 'Modifying the bound mailbox only affects the mailbox used for login, which will not affect the rights of your current account.',
        destoryAccountTip: 'Cancel the account will delete all personal data and internet history including workspace and codes.',
        copySSHkeyTip: 'Please add the SSH public key to personal public key list so that you can push and pull the codes.',
        nameRegTip: 'Please enter 6~10 characters starting with a letter, which only contains letters or numbers',
        emailRegTip: 'Please enter an email address within 50 characters',
        emailRegTip1: 'Email format is incorrect',
        emailRegTip2: 'Email must end with paxsz.com',
        numberTip: 'Please enter an integer from 1 to 999',
        ipTip: 'IP format is incorrect',
        userNameTip: 'Please enter 6-10 digits or letters',
        lengthTip: 'Please enter {min}-{max} digits or letters',
        pswTip: 'Please enter a 6-20 characters password',
        pswRegTip: 'Password must contain uppercase and lowercase letters and numbers',
        confirmPswTip: 'Please enter the password again',
        pswDifferentTip: 'The two passwords do not match',
        spaceNameDifferentTip: 'The space name do not match',
        pswSameTip: 'The new password cannot be the same as the old password',
        phoneTip: 'Please enter your contact information within 20 characters',
        activatedSuccessTip: 'Your account has been activated successfully, please click Login.',
        resetPswTip: 'Your password has been reset successfully, please click Login.',
        bindEmailTip: 'Your email has been bound successfully, please click Login.',
        disableUserTip: 'Once disabled, the user and all team member accounts will be unavailable',
        removeUserTip: 'Whether to remove user {name}?',
        deleteUserTip: 'Whether to delete user {name}?',
        deleteTeamTip: 'Whether to delete team {name}?',
        deleteResourceTip: 'Whether to delete {name} {resource} ?',
        sendEmailTip: 'Whether to resend the activation email?',
        sendEmailSuccessTip: 'The activation email has been sent, please remind user to complete account activation',
        changeEmailSuccessTip: 'We will send an email to your {email}, please check it and complete the operation according to the mail within 48 hours',
        termTip: 'Please read and agree to the EULA and Privacy Policy',
        modifyPhoneTip:
            'Contact information can be modified and deleted at any time. By filling in, you agree to our collection of this data. Please see our Privacy Policy to learn more.',
        deleteSpaceTip:
            'Space cannot be recovered after deletion. If you need to delete, please enter {name} (the name of the space to be deleted),and confirm',
        inviteSuccTip: "Joined the '{name}' team successfully",
        expiredTip: 'Expired, recharge to renew it',
        trialExpriedTip: 'Trial space will expire at {time}',
        teamExpriedTip: 'Team space will expire at {time}',
        trialRenewalTip: 'The current trial space has reached the limit, please delete the existing space or contact the administrator to upgrade',
        trialRenewalTip1: 'This trial space has expired',
        trialRunningTip: 'The maximum number of trial spaces that can run concurrently has been reached',
        teamRunningTip: 'The maximum number of team spaces that can run concurrently has been reached',
        teamRenewalTip: 'This team space has expired',
        trialNumRenewalTip:
            'The current trial space has reached the limit, please delete the existing space and then add it or contact the administrator to upgrade',
        teamNumRenewalTip:
            'The current team space has reached the limit, please delete the existing space and then add it or contact the administrator to upgrade'
    },
    holder: {
        regNameHolder: 'only contains letters or numbers',
        warehouseAddressTip: 'Please enter the Git repository URL, HTTPS can only clone public repository ',
        regPasswordHolder: 'contains uppercase and lowercase letters and numbers',
        enterHolder: 'Please enter',
        selectHolder: 'Please select',
        inputHolder: 'Please enter the {key}',
        teamNameHolder: 'Please enter the team name',
        warehouseAddressHolder: 'Please enter the repository',
        emailHolder: 'Please enter the email',
        phoneHolder: 'Please enter the phone number',
        nameHolder: 'Please enter the name',
        passwordHolder: 'Please enter the password',
        infoPasswordHolder: "Please enter the info system's password",
        checkPasswordHolder: 'Confirm the password'
    }
}
export default en
