// axios 二次封装
import axios, { AxiosRequestConfig } from 'axios'

export interface ResponseData {
    message: string
    code: number
    data: Record<string, unknown>
}

export interface AxiosFunc {
    (url: string, params?: AxiosRequestConfig['params']): Promise<ResponseData>
}

export interface RequestFunc {
    (params?: AxiosRequestConfig['params'], ...rest): Promise<ResponseData>
}

export const GET: AxiosFunc = async (url, originParams) => {
    if (originParams) {
        const params = []
        Object.keys(originParams).forEach((key) => {
            const val = encodeURIComponent(originParams[key])
            params.push(`${key}=${val}`)
        })
        url = `${url}?${params.join('&')}`
    }
    const res = await axios.get(url)
    return res.data
}

export const POST: AxiosFunc = async (url, params) => {
    const res = await axios.post(url, params)
    return res.data
}

export const PUT: AxiosFunc = async (url, params) => {
    const res = await axios.put(url, params)
    return res.data
}

export const DELETE: AxiosFunc = async (url, params) => {
    const res = await axios.delete(url, { params })
    return res.data
}

export default axios
