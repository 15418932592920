<template>
    <ConfigProvider :locale="locale === 'zh-CN' ? zhCN : enUS">
        <router-view />
    </ConfigProvider>
</template>
<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn'
export default defineComponent({
    setup() {
        const { locale } = useI18n()

        return {
            enUS,
            zhCN,
            locale
        }
    }
})
</script>
